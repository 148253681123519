<template>
  <div ref="targetEl" :style="`min-height:${fixedMinHeight ? fixedMinHeight : minHeight}px`">
    <slot v-if="shouldRender" />
  </div>
</template>

<script setup lang="ts">
import { ref, nextTick } from 'vue';
import { useIntersectionObserver } from '@vueuse/core';

const shouldRender = ref(false);
const targetEl = ref();
const fixedMinHeight = ref(0);
let unrenderTimer: any;
let renderTimer: any;

const props = defineProps({
  renderOnIdle: {
    type: Boolean,
    default: false
  },
  minHeight: {
    type: Number,
    default: 200
  },
  unrender: {
    type: Boolean,
    default: true
  },
  unrenderDelay: {
    type: Number,
    default: 10000
  },
  rootMargin: {
    type: Number,
    default: 800
  }
});

function onIdle(cb = () => {}) {
  if ('requestIdleCallback' in window) {
    window.requestIdleCallback(cb);
  } else {
    setTimeout(() => {
      nextTick(cb);
    }, 300);
  }
}

const { stop } = useIntersectionObserver(
  targetEl,
  ([{ isIntersecting }]) => {
    if (isIntersecting) {
      // perhaps the user re-scrolled to a component that was set to unrender. In that case stop the unrendering timer
      clearTimeout(unrenderTimer);
      // if we're dealing underndering lets add a waiting period of 150ms before rendering. If a component enters the viewport and also leaves it within 150ms it will not render at all. This saves work and improves performance when user scrolls very fast
      if (props.unrender) {
        renderTimer = setTimeout(() => (shouldRender.value = true), props.unrender ? 150 : 0);
      } else {
        shouldRender.value = true;
      }

      if (!props.unrender) {
        stop();
      }
    } else if (props.unrender) {
      // if the component was set to render, cancel that
      clearTimeout(renderTimer);
      unrenderTimer = setTimeout(() => {
        fixedMinHeight.value = targetEl.value?.clientHeight;
        shouldRender.value = false;
      }, props.unrenderDelay);
    }
  },
  {
    rootMargin: `${props.rootMargin}px`
  }
);

if (props.renderOnIdle) {
  onIdle(() => {
    shouldRender.value = true;
    stop();
  });
}
</script>
